// Get computed styles
function getStyle(property) {
  const style = getComputedStyle(document.body);
  const propertyValue = style.getPropertyValue(property);
  return propertyValue ? propertyValue.trim() : 'undefined';
}

class ThemeStyles {
  colors() {
    return {
      gray: {
        50: getStyle('--x-gray-50'),
        100: getStyle('--x-gray-100'),
        200: getStyle('--x-gray-200'),
        300: getStyle('--x-gray-300'),
        400: getStyle('--x-gray-400'),
        500: getStyle('--x-gray-500'),
        600: getStyle('--x-gray-600'),
        700: getStyle('--x-gray-700'),
        800: getStyle('--x-gray-800'),
        900: getStyle('--x-gray-900')
      },
      theme: {
        primary: getStyle('--x-primary'),
        primarySubtle: getStyle('--x-primary-subtle'),
        secondary: getStyle('--x-secondary'),
        tertiary: getStyle('--x-tertiary'),
        info: getStyle('--x-info'),
        success: getStyle('--x-success'),
        danger: getStyle('--x-danger'),
        warning: getStyle('--x-warning'),
        light: getStyle('--x-light'),
        dark: getStyle('--x-dark')
      },
      transparent: 'transparent',
      body: {
        color: getStyle('--x-body-color'),
        fontSize: getStyle('--x-body-font-size')
      },
      border: {
        width: getStyle('--x-border-width'),
        color: getStyle('--x-border-color')
      }
    };
  }

  fonts() {
    return {
      base: getStyle('--x-font-sans-serif'),
      code: getStyle('--x-font-monospace'),
      serif: getStyle('--x-font-serif')
    };
  }
}

export default ThemeStyles;
