import Choices from 'choices.js';

export function choicesPlugin() {
  function init(el) {
    const items = [
      {
        value: 'Value 1',
        label: '<img src="/img/crypto/bitcoin.svg" class="w-rem-6 h-rem-6 me-2" /> Bitcoin',
        id: 1
      },
      {
        value: 'Value 2',
        label: 'Label 2',
        id: 2,
        customProperties: {
          random: 'I am a custom property'
        }
      }
    ];

    const options = {
      choices: items,
      allowHTML: true
    };

    new Choices(el, options);
  }

  return {
    init
  };
}
