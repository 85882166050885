import ApexCharts from 'apexcharts';
import ThemeStyles from '../utils/styles';

function apexSparkChart() {
  this.init = function (chartEl) {
    const styles = new ThemeStyles();

    function init(chartEl) {
      const options = {
        chart: {
          width: '100%',
          sparkline: { enabled: true },
          animations: { enabled: false }
        },
        series: [],
        labels: [],
        plotOptions: {
          bar: { columnWidth: '40%', endingShape: 'rounded' }
        },
        stroke: { curve: 'smooth' },
        markers: { size: 0 },
        colors: [],
        tooltip: {
          fixed: { enabled: false },
          x: { show: false },
          y: {
            title: {
              formatter: function (e) {
                return '';
              }
            }
          },
          marker: { show: false }
        }
      };

      // Get data from data attributes
      const dataset = chartEl.dataset.dataset;
      const labels = chartEl.dataset.labels;
      const color = chartEl.dataset.color;
      const height = chartEl.dataset.height;
      const type = chartEl.dataset.type;
      const stroke = chartEl.dataset.stroke;

      // Inject dynamic properties
      options.series = [{ data: dataset.split(',') }];
      if (labels) {
        options.labels = [labels];
      }
      options.colors = [styles.colors().theme[color]];

      options.chart.height = height ? height : 35;
      options.chart.type = type ? type : 'line';
      options.stroke.width = stroke ? stroke : 2;

      // Create chart
      setTimeout(function () {
        new ApexCharts(chartEl, options).render();
      }, 300);
    }

    if (chartEl) {
      init(chartEl);
    }
  };
}

export default apexSparkChart;
