import '../scss/fonts.scss';
import '../scss/main.scss';
import '../scss/utility.scss';

// Bootstrap JS
import { Tooltip, Popover } from 'bootstrap';

// Datepicker
import { datepicker } from './libs/datepicker';

// Choices
import { choicesPlugin } from './libs/choices';

document.addEventListener('DOMContentLoaded', () => {
  // Tooltip
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    new Tooltip(tooltipTriggerEl);
  });

  // Popover
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  popoverTriggerList.forEach((popoverTriggerEl) => {
    new Popover(popoverTriggerEl);
  });

  // Datepicker
  const dateRangePickerEl = document.querySelector('.daterangepicker');
  if (dateRangePickerEl) {
    datepicker().range(dateRangePickerEl);
  }

  const datepickerEl = document.querySelector('.datepicker');
  if (datepickerEl) {
    datepicker().init(datepickerEl);
  }

  // Choices
  const choicesEl = document.querySelector('.js-choice');
  if (choicesEl) {
    choicesPlugin().init(choicesEl);
  }
});
