const getSystemTheme = () => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }
  return 'light';
};

const getStoredTheme = () => localStorage.getItem('theme');

const getPreferredTheme = () => {
  const storedTheme = getStoredTheme();
  if (storedTheme === 'light' || storedTheme === 'dark') {
    return storedTheme;
  }
  return getSystemTheme();
};

const setTheme = (theme) => {
  document.documentElement.setAttribute('data-bs-theme', theme);
};

const toggleNavbarMode = (theme) => {
  const navbar = document.getElementById('navbar');
  if (!navbar) return;

  navbar.classList.remove('navbar-dark', 'navbar-light', 'bg-transparent');
  navbar.classList.add(`navbar-${theme}`, 'bg-transparent');
};

const toggleSidebarMode = (theme) => {
  const sidebar = document.getElementById('sidebar');
  if (!sidebar) return;

  sidebar.classList.remove('navbar-dark', 'navbar-light', 'bg-transparent');
  sidebar.classList.add(`navbar-${theme}`, 'bg-transparent');
};

const showActiveTheme = (theme) => {
  const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`);

  document.querySelectorAll('[data-bs-theme-value]').forEach((element) => {
    element.classList.remove('active');
  });

  btnToActive.classList.add('active');

  toggleNavbarMode(theme);
  toggleSidebarMode(theme);
};

const applyTheme = () => {
  const preferredTheme = getPreferredTheme();
  setTheme(preferredTheme);
  showActiveTheme(preferredTheme);
};

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', applyTheme);

window.addEventListener('DOMContentLoaded', () => {
  applyTheme();

  document.querySelectorAll('[data-bs-theme-value]').forEach((toggle) => {
    toggle.addEventListener('click', () => {
      const theme = toggle.getAttribute('data-bs-theme-value');
      localStorage.setItem('theme', theme);
      applyTheme();
    });
  });
});
