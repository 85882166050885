import flatpickr from 'flatpickr';

export function datepicker() {
  function init(el) {
    const options = {
      enableTime: false,
      allowInput: true,
      wrap: true,
      dateFormat: 'M d, Y',
      position: 'right'
    };
    flatpickr(el, options);
  }

  function range(el) {
    const options = {
      enableTime: false,
      allowInput: true,
      wrap: true,
      dateFormat: 'M d, Y',
      mode: 'range',
      defaultDate: ['Jan 23, 2022', 'Jan 30, 2022']
      // position: 'right'
    };
    flatpickr(el, options);
  }

  return {
    init,
    range
  };
}
