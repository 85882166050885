// Bootstrap JS
import { Tooltip, Popover } from 'bootstrap';

// Charts
import ApexChartLine from './apex-charts/line';
import ApexChartBar from './apex-charts/bar';
import ApexChartBarStacked from './apex-charts/bar-stacked';
import ApexChartCryptoPrice from './apex-charts/crypto-price';
import ApexChartUsers from './apex-charts/users';
import ApexChartSpark from './apex-charts/spark';

// Libs
import { datepicker } from './libs/datepicker';
import { choicesPlugin } from './libs/choices';

document.addEventListener('DOMContentLoaded', () => {
  // Tooltip
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    new Tooltip(tooltipTriggerEl);
  });

  // Popover
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  popoverTriggerList.forEach((popoverTriggerEl) => {
    new Popover(popoverTriggerEl);
  });

  // Line chart
  const charLine = document.querySelector('#chart-line');
  if (charLine) {
    new ApexChartLine().init(charLine);
  }

  // Bar chart
  const chartBar = document.querySelector('#chart-bar');
  if (chartBar) {
    new ApexChartBar().init(chartBar);
  }

  // BarStacked chart
  const chartBarStacked = document.querySelector('#chart-bar-stacked');
  if (chartBarStacked) {
    new ApexChartBarStacked().init(chartBarStacked);
  }

  // Users chart
  const chartUsers = document.querySelector('#chart-users');
  if (chartUsers) {
    new ApexChartUsers().init(chartUsers);
  }

  // Crypto price chart
  const chartCryptoPrice = document.querySelector('#chart-crypto-price');
  if (chartCryptoPrice) {
    new ApexChartCryptoPrice().init(chartCryptoPrice);
  }

  // Spark chart
  const sparkCharts = document.querySelectorAll("[data-toggle='spark-chart']");
  sparkCharts.forEach((el) => {
    new ApexChartSpark().init(el);
  });

  // Datepicker
  const dateRangePickerEl = document.querySelector('.daterangepicker');
  if (dateRangePickerEl) {
    datepicker().range(dateRangePickerEl);
  }

  const datepickerEl = document.querySelector('.datepicker');
  if (datepickerEl) {
    datepicker().init(datepickerEl);
  }

  // Choices
  const choicesEl = document.querySelector('.js-choice');
  if (choicesEl) {
    choicesPlugin().init(choicesEl);
  }
});
