import ApexCharts from 'apexcharts';
import ThemeStyles from '../utils/styles';
import dayjs from 'dayjs';
import priceData from './prices';

function apexCryptoPriceChart() {
  this.init = function (chartEl) {
    const styles = new ThemeStyles();
    const colorPalette = [styles.colors().theme.primary, styles.colors().theme.light, styles.colors().theme.dark];

    function init($this) {
      const options = {
        chart: {
          stacked: false,
          zoom: { enabled: false },
          toolbar: { show: false },
          shadow: { enabled: false },
          animations: { enabled: false },
          fontFamily: styles.fonts().base
        },
        colors: colorPalette,
        stroke: {
          width: 3,
          curve: 'smooth',
          lineCap: 'butt',
          dashArray: [0, 3]
        },
        series: [
          {
            name: 'Price',
            data: priceData.map((d) => {
              return {
                x: new Date(d.date),
                y: [d.open, d.open, d.close, d.close]
              };
            })
          }
        ],
        markers: { size: 0 },
        xaxis: {
          type: 'datetime',
          tooltip: { enabled: false },
          axisBorder: { show: false },
          axisTicks: { show: true },
          tooltip: {
            enabled: true,
            formatter: function (val) {
              return dayjs(val).format('MMM YYYY');
            }
          },
          labels: {
            style: {
              colors: '#999',
              fontSize: '13px',
              fontFamily: '#333',
              cssClass: 'apexcharts-xaxis-label'
            },
            show: true,
            showDuplicates: false,
            formatter: function (val) {
              return dayjs(val).format('MMM YYYY');
            }
          }
        },
        yaxis: [
          {
            seriesName: 'Price',
            axisBorder: { show: false },
            axisTicks: { show: true },
            labels: {
              style: {
                colors: '#999',
                fontSize: '13px',
                fontFamily: 'inherit',
                cssClass: 'apexcharts-xaxis-label'
              }
            },
            opposite: false
          }
        ],
        legend: { show: false },
        grid: {
          borderColor: styles.colors().border.color,
          strokeDashArray: 3
        },
        dataLabels: { enabled: false },
        tooltip: {
          x: {
            formatter: function (val) {
              return dayjs(val).format('MMM DD, YYYY');
            }
          }
        }
      };

      // Get data from data attributes
      const height = $this.dataset.height;

      // Inject dynamic properties
      options.colors = colorPalette;
      options.chart.height = height ? height : 350;

      // Create chart
      new ApexCharts($this, options).render();
    }

    if (chartEl) {
      init(chartEl);
    }
  };
}

export default apexCryptoPriceChart;
